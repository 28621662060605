<template>
    <table>
        <tbody>
            <tr class="font-bold">
                <td width="350" />
                <td width="150">
                    <p class="break-normal flex justify-end pb-2">
                        {{ formatDate(data.period.startDate) }}<br />
                        - {{ formatDate(data.period.endDate) }}
                    </p>
                </td>
                <td class="break-normal flex justify-end" width="150">
                    <p>
                        {{ formatDate(data.previousPeriod.startDate) }}<br />
                        - {{ formatDate(data.previousPeriod.endDate) }}
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import moment from "moment";
export default {
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return " ";
            return moment(date).format("YYYY.MM.DD");
        },
    },
};
</script>
